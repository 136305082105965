import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center text-sm text-grey-darker mt-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_meta_handler = _resolveComponent("meta-handler")!
  const _component_auth_container = _resolveComponent("auth-container")!

  return (_openBlock(), _createBlock(_component_auth_container, null, {
    default: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "w-full flex flex-col justify-start items-start gap-y-4 overflow-auto scrollbar pr-1" }, null, -1)),
      _createElementVNode("h6", null, _toDisplayString(_ctx.$t("auth.email_send")), 1),
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t("auth.email_send_text")), 1),
      _createVNode(_component_custom_button, {
        id: 'back-to-login',
        class: "w-full mt-5",
        buttonStyle: _ctx.buttonStyle,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: _ctx.cancelRoute })))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("auth.to_login")), 1)
        ]),
        _: 1
      }, 8, ["buttonStyle"]),
      _createVNode(_component_custom_button, {
        id: 'forgot-password-button',
        class: "w-full mt-5",
        isPlainButton: true,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.userForgotPassword()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("auth.resend_email")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_meta_handler, {
        id: 'email',
        name: 'email'
      })
    ]),
    _: 1
  }))
}