
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import AuthContainer from "./containers/AuthContainer.vue";
import { computed, defineComponent, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/store/auth";
import { useHelpersStore } from "@/store/helpers";
import { storeToRefs } from "pinia";
import { MetaInformationInterface } from "@/store/helpers/interfaces";
import { useI18n } from "vue-i18n";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
import MetaHandler from "@/components/inputs/metaHandler/MetaHandler.vue";
export default defineComponent({
    name: "ForgotPassword",
    components: { AuthContainer, CustomButton, MetaHandler },
    setup() {
        const { t } = useI18n();

        const { forgotPassword } = useAuthStore();
        const { resetPasswordMail } = storeToRefs(useAuthStore());
        const { errorMessages } = storeToRefs(useHelpersStore());
        const { setLoadingId, removeLoadingId, setToastMessage } =
            useHelpersStore();
        const route = useRoute();
        const buttonStyle = computed(() => {
            let style = {};
            if (route.name?.toString().includes("CustomerAuth")) {
                style = {
                    standardBackgroundColor: "black-lightest",
                    standardBackgroundHoverColor: "black-light",
                    standardBackgroundPressedColor: "black-light",
                    standardBorderPressedColor: "black-light",
                    standardBorderFocusColor: "black-light",
                };
            }
            return style;
        });
        const metaInformation = computed(() => {
            let metaInfos: MetaInformationInterface = {};
            errorMessages.value.forEach((error: MetaInformationInterface) => {
                if (error.name == "email" && error.value) {
                    metaInfos.errorMessage = error.value;
                }
            });
            if (
                !metaInfos.errorMessage &&
                !metaInfos.infoMessage &&
                !metaInfos.saveMessage
            ) {
                return undefined;
            } else {
                return metaInfos;
            }
        });

        async function userForgotPassword(): Promise<void> {
            setLoadingId("forgot-password-button");
            errorMessages.value = [];
            const response = await forgotPassword({
                email: resetPasswordMail.value,
            });
            removeLoadingId("forgot-password-button");
            if (response?.status == 200) {
                setToastMessage(
                    ToastMessageType.SUCCESS,
                    t("toast_message.resend_email")
                );
            } else {
                setToastMessage(
                    ToastMessageType.ERROR,
                    t("toast_message.resend_email_failed")
                );
            }
        }

        const cancelRoute = computed(() => {
            let routeName = "AuthLogin";
            if (route.name?.toString().includes("CustomerAuth")) {
                routeName = "CustomerAuthLogin";
            }
            return routeName;
        });

        onUnmounted(() => {
            errorMessages.value = [];
        });

        return {
            buttonStyle,
            cancelRoute,
            userForgotPassword,
            metaInformation,
        };
    },
});
